@import "~antd/dist/antd.css";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: "Fira Sans", sans-serif;
}

@media (max-width: 768px) {
  html {
    font-size: 52.5%;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 42.5%;
  }
}
